import * as React from "react"
import { FunctionComponent } from "react"
import Login from "../components/pages/login"
import { useLocalAuthFile } from "../common/authentication/auth"
import { USER_TYPE } from "../common/constants"
import { Helmet } from "react-helmet"

const Index: FunctionComponent = () => {
  let body = <Login />
  let localAuthUser = useLocalAuthFile()

  console.log("USER_TYPE", localAuthUser.userType, USER_TYPE[localAuthUser.userType])

  if (localAuthUser.isLogin && USER_TYPE[localAuthUser.userType] === undefined) {
    // ログイン済でユーザタイプが不明な場合は以下に飛ばす
    body = (
      <p style={{
        textAlign: "center",
        marginTop: "50px",
        opacity: 1,
        visibility: "visible",
      }}
      >
        権限がありません
      </p>
    )
  }

  const fadeBody = (
    <>
      <Helmet>
        <title>VENDIN</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="fadeWrap">
        {body}
      </div>
    </>
  )

  return fadeBody
}

export default Index
